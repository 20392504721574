@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@200;300;400;500;600;700;800&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  outline: none;
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --gray: #848484;
  --purple:#4D0695;
  --dark-gray:#2E2F35;
  --pd-lft:3rem;
}

.swiper-pagination-container {
  position: relative !important;
}

#swiper-container {
  padding-bottom: 3rem;
}

.swiper-pagination-bullet {
  background-color: white !important;
}

#works-swiper {
  padding-bottom: 2rem;
}
#works-swiper .swiper-pagination-bullet {
  background-color: var(--purple) !important;
}

#header-container {
  display: inline-flex;
  padding-left: 3rem;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
}

.header-layer {
  display: inline-flex;
  padding: 56px 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

#header p, h1 {
  color: var(--dark-gray);
  font-family: "Merriweather";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 1px;
}

#header h1 {
  color: var(--purple);
  font-size: 96px;
  font-weight: 800;
}

#header-body {
  color: #58595D;
  font-family: "Manrope";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px; /* 170% */
  width: 553px;
}

#header-container button {
  padding: 12px 25px;
  border-radius: 12px;
  border: 2px solid white;
  background: var(--purple);
  box-shadow: 3px 3pxvar --dark-gray;
  color: white;
  cursor: pointer;
  transition: 2s ease;
}

#header-container button:hover {
  border: 2px solid var(--purple);
  background: #FFF;
  box-shadow: 3px 3px var(--purple);
  color: var(--purple);
}

.bg-img {
  width: 796.806px;
  height: 530.264px;
  background-image: url("../public/images/bg-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column !important;
  justify-content: space-between;
  position: relative;
  gap: 1rem;
}

#top-img {
  display: flex;
  width: 248.59px;
  height: 164.691px;
  padding: 8px 22px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 12px;
  border: 2px solid #2E2F35;
  background: #FFF;
  box-shadow: 3px 3px 0px 0px #2E2F35;
  position: relative;
  justify-content: center;
  margin-left: 2.5rem;
}

#top-img .img {
  width: 233.053px;
  height: 147.6px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #000;
  background: url("../public/images/top-img.png"), lightgray 50%/cover no-repeat;
}

#buttom-img {
  display: flex;
  width: 200.881px;
  height: 133.084px;
  padding: 5px 22px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 12px;
  border: 2px solid #2E2F35;
  background: #FFF;
  box-shadow: 3px 3px 0px 0px #2E2F35;
  justify-content: center;
  margin-top: 14.5rem;
  margin-left: 2.5rem;
}

#buttom-img .img {
  width: 188.326px;
  height: 119.273px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #000;
  background: url("../public/images/buttom-img.png");
  z-index: 100;
}

#about {
  position: relative;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding-bottom: 80px;
}

.about-container {
  height: -moz-fit-content;
  height: fit-content;
  padding: 174px 120px 0 96px;
  margin-inline: 3.5rem;
}

.about-container h3 {
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  color: #2E2F35;
}

.about-container h1 {
  font-family: "Manrope";
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -2px;
  text-align: center;
  margin: 35px 0 !important;
}

.about-container h1 span {
  color: var(--purple) !important;
  font-family: "Manrope";
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -2px;
}

.about-container span {
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  position: relative;
}

.grid-container {
  display: flex;
  gap: 54px;
  position: relative;
  margin-top: 43px;
  margin-inline: 3.5rem;
  justify-content: center;
}
.grid-container #story,
.grid-container #vision,
.grid-container #mission {
  display: flex;
  padding: 87px 33px 155px 33px;
  flex-direction: column;
  align-items: center;
  width: 380px;
  height: 406px;
  border-radius: 13px;
  transition: ease 1s;
}
.grid-container #story h4,
.grid-container #vision h4,
.grid-container #mission h4 {
  color: #2E2F35;
  text-align: center;
  font-family: "Manrope";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 138.462% */
}
.grid-container #story p,
.grid-container #vision p,
.grid-container #mission p {
  color: #58595D;
  text-align: center;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 187.5% */
}

#story:hover {
  background: #FBE4E7;
}

#vision:hover {
  background: #D4D8FE;
}

#mission:hover {
  background: #C1F2FF;
}

#story:hover .story-icon {
  opacity: 1;
}

#vision:hover .vision-icon {
  opacity: 1;
}

#mission:hover .mission-icon {
  opacity: 1;
}

.icon-con {
  position: absolute;
  top: 0;
  transition: transform 2s ease;
  transform: translateY(4rem);
}

#mission:hover .icon-con {
  transform: translateY(3rem);
}

#story:hover .icon-con {
  transform: translateY(3rem);
}

#vision:hover .icon-con {
  transform: translateY(3rem);
}

.layer {
  transition: transform 2s ease;
}

#story:hover .layer {
  transform: translateY(7rem);
}

#mission:hover .layer {
  transform: translateY(7rem);
}

#vision:hover .layer {
  transform: translateY(7rem);
}

.story-icon {
  background: url("../public/images/story-icon.png");
  padding: 60px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 30px;
  position: relative;
  opacity: 0;
  transition: ease 1s;
}

.vision-icon {
  background: url("../public/images/vision-icon.png") !important;
  padding: 60px !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  margin-bottom: 30px;
  position: relative;
  opacity: 0;
  transition: ease 1s;
}

.mission-icon {
  background: url("../public/images/mission-icon.png") !important;
  padding: 60px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  margin-bottom: 30px;
  position: relative;
  opacity: 0;
  transition: 2s ease;
}

#offer {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 80px;
  padding-inline: var(--pd-lft);
  background: #4D0695;
  position: relative;
  overflow-x: hidden;
}
#offer .offer-header {
  width: 1029px;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  padding-top: 44px;
  color: #FDFBFF !important;
  margin: auto;
  text-align: center;
  font-family: "Manrope";
}
#offer .offer-header h2 {
  text-align: center;
  padding-inline: 4rem;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 125% */
  letter-spacing: -2px;
}
#offer .offer-header h6 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px; /* 145.833% */
}

#swiper {
  margin-top: 100px;
  width: 100%;
  position: relative;
}

.slider-container {
  display: flex;
  padding: 55px 25px 53px 25px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 13px;
  color: #000;
  width: 380px;
  height: 400px;
  flex-shrink: 0;
}
.slider-container .slide-layer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  font-family: "Manrope";
}
.slider-container .slide-layer h1 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}
.slider-container .slide-layer span {
  width: 359px;
  color: #58595D;
  text-align: center;
  font-family: " Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

#works-container {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 200px;
  position: relative !important;
  align-items: center !important;
  padding-inline: 3rem;
  text-align: center;
  align-content: center !important;
  display: block;
  padding-bottom: 260px !important;
}
#works-container .works-header {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  width: 1008px;
  text-align: center;
  padding-inline: 1.5rem;
}
#works-container .works-header h4 {
  color: #58595D;
  text-align: center;
  font-family: "Manrope";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px; /* 145.833% */
}
#works-container .works-header h1 {
  color: #2E2F35;
  text-align: center;
  font-family: "Manrope";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 55px; /* 114.583% */
  letter-spacing: -2px;
}
#works-container .works-header h1 span {
  color: #4D0695;
}
#works-container .work-layer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  width: 100%;
}
#works-container .work-layer .layer-left,
#works-container .work-layer #layer-right {
  margin-top: 30rem !important;
}
#works-container .work-layer #layer-left {
  margin-top: 5rem !important;
}
#works-container .work-layer .layer-left,
#works-container .work-layer .layer-right {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 370px;
  height: 1683px;
  margin-top: 5rem;
  padding-left: 3rem;
}

.talents-container {
  height: 1868px !important;
  padding-bottom: 0 !important;
}

#layer-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 35px !important;
  text-align: left;
}
#layer-content .header {
  width: 563px;
  height: -moz-fit-content;
  height: fit-content;
  font-family: "Manrope";
}
#layer-content .header h2 {
  color: #4D0695;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px; /* 114.583% */
}
#layer-content .header h3 {
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 55px;
}
#layer-content .content {
  display: flex;
  padding: 34px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 15px;
  border: 2px solid rgba(77, 6, 149, 0.5);
  position: relative;
}
#layer-content .content p {
  width: 426.091px;
  color: #58595D;
  font-family: "Manrope";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px; /* 170% */
}
#layer-content .content p a {
  color: #4D0695;
  text-decoration: none;
}
#layer-content .content i {
  font-size: 34px;
  background-color: white;
  color: #4D0695;
  position: absolute;
  left: 3rem;
  top: -1rem;
}

#swiper-works {
  display: none;
}

#test-slider {
  height: 298px;
  padding: 34px;
  display: flex;
  flex-direction: column;
  gap: 34px;
  justify-content: flex-start;
}

#testimonials {
  width: 950px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  font-family: "Manrope";
}
#testimonials #h6 {
  font-size: 20px;
}
#testimonials #h2 {
  line-height: 76px;
}

#info {
  display: flex;
  width: 316px;
  justify-content: space-between;
  position: relative;
}
#info #name {
  width: 166px;
  display: flex;
  position: relative;
  gap: 16px;
  font-family: "Manrope";
}
#info #name div h6 {
  color: #4D0695;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding-top: 0.5rem;
}
#info #name div p {
  color: #58595D;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
#info #icon i {
  font-size: 32px;
  color: #4D0695;
}

#body span {
  width: 348px;
  color: #58595D;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.testimonials {
  align-items: center;
  text-align: center;
  margin-top: 5rem !important;
}
.testimonials button {
  padding: 12px 22px;
  border-radius: 12px;
  border: 2px solid #4D0695;
  background: #FFF;
  box-shadow: 3px 3px 0px 0px #4D0695;
  color: #4D0695;
  margin-top: 7rem;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  transition: 2s ease;
}
.testimonials button:hover {
  border: 2px solid #FFF;
  background: #4D0695;
  box-shadow: 3px 3px 0px 0px #FFF;
  color: #FFF;
}

#questions {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 100px 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 11px;
  font-family: "Manrope";
  position: relative;
}
#questions .question-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 3.5rem;
}
#questions .question-header h4 {
  color: #2E2F35;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px; /* 145.833% */
}
#questions .question-header h1 {
  color: #2E2F35;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -2px;
  font-family: "Manrope";
}
#questions .contents-container {
  width: 100%;
  display: flex;
  padding: 10px 16px;
  align-items: flex-start;
  gap: 65px;
  align-self: stretch;
  border-radius: 5px;
  background: rgba(77, 6, 149, 0.04);
  cursor: pointer;
}
#questions .contents-container .layer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
#questions .contents-container .layer .txt-contents {
  width: 493px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
#questions .contents-container .layer .txt-contents span {
  color: rgba(0, 0, 0, 0.49);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
  padding-bottom: 2rem !important;
}
#questions .contents-container .layer .txt-contents h6 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
}
#questions .contents-container .layer i {
  font-size: 20px;
}

footer {
  width: 100%;
  padding: 68px 75px;
  gap: 70px;
  background: #4D0695;
  font-family: "Manrope";
}
footer .footer-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-inline: 1rem;
}
footer .footer-ads {
  display: flex;
  flex-direction: column;
  gap: 11px;
  width: 383px;
  padding-inline: 10px;
}
footer img {
  border-radius: 50%;
}
footer h6 {
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 96px; /* 400% */
}
footer span {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
footer .footer-contents {
  width: 365px;
  height: 203px;
  gap: 103px;
  display: inline-flex;
  position: relative;
}
footer #company {
  display: flex;
  flex-direction: column;
  gap: 21px;
}
footer p {
  font-family: " Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #FFF;
}
footer ul {
  font-size: "Manrope";
  color: #FFF;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 13px;
}
footer #ul {
  padding-top: 95px !important;
}
footer li {
  line-height: 30px;
}
footer a {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  text-decoration: none;
  color: #FFF;
}
footer hr {
  border: 1px solid white;
  margin-block: 30px;
}
footer #foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  font-family: "Manrope";
  padding-inline: 1.5rem;
}
footer h5 {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
footer .footer-btn {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
}
footer #login {
  padding: 14px 22px;
  border-radius: 12px;
  border: 2px solid #FFF;
  background: #FFF;
  box-shadow: 3px 3px 0px 0px #2E2F35;
  color: #4D0695;
}
footer #signup {
  padding: 14px 26px;
  border-radius: 12px;
  border: 2px solid #FFF;
  background: #4D0695;
  box-shadow: 3px 3px 0px 0px #2E2F35;
  color: #FFF;
}
footer .footer-contents-mobile {
  display: none;
}

#sign-up-container {
  position: fixed;
  width: 100vw;
  height: 100vh !important;
  top: 0;
  z-index: 1000;
  display: block;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
#sign-up-container #sign-in-layer {
  width: 80%;
  margin: auto;
  border-radius: 1rem;
  background-color: white;
  display: flex;
  justify-content: space-between;
}
#sign-up-container .lists-container {
  background-color: #4D0695;
  font-family: "Manrope";
  width: 398px;
  align-items: center;
  padding: 95px 50px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
#sign-up-container #img {
  border-radius: 50%;
  height: 114px;
  width: 114px;
  overflow: hidden;
  margin-bottom: 47px !important;
}
#sign-up-container #img img {
  width: 100%;
  height: 100%;
}
#sign-up-container .list-layer {
  text-align: start;
  transition: 2s ease;
}
#sign-up-container ul {
  display: flex;
  flex-direction: column;
  padding: 0;
}
#sign-up-container ul p {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 30px;
}
#sign-up-container li {
  list-style-type: decimal;
  list-style: decimal !important;
  margin-left: 1rem;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 214.286% */
  letter-spacing: 1px;
}
#sign-up-container form {
  display: block;
  position: relative;
  padding: 1rem 76px;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: auto;
  height: 90vh;
  width: 650px !important;
  margin-top: 5%;
}
#sign-up-container form::-webkit-scrollbar {
  display: none;
}
#sign-up-container .header {
  margin-bottom: 25px !important;
  display: flex;
  flex-direction: column;
  gap: 23px;
  text-align: center;
}
#sign-up-container .header h3 {
  color: #4D0695;
  text-align: center;
  font-family: "Lato";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 55.556% */
}
#sign-up-container .header h6 {
  color: #141413;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
#sign-up-container .header h6 a {
  color: #4D0695;
}
#sign-up-container #redio-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 35px;
}
#sign-up-container #radio {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 5px;
  background: rgba(77, 6, 149, 0.1);
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  text-align: center !important;
  justify-items: center !important;
  cursor: pointer;
}
#sign-up-container #radio h5 {
  color: #141413;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  padding-top: 5px;
}
#sign-up-container #radio input, #sign-up-container #radio #input textarea, #sign-up-container #input #radio textarea {
  padding: 24px;
  margin: 16px;
}
#sign-up-container #forms {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
#sign-up-container #input {
  width: 100%;
  border: 1px solid gray;
  display: flex;
  gap: 12px;
  padding-left: 12px;
  align-items: center;
  border-radius: 4px;
  overflow-x: hidden;
}
#sign-up-container #input i {
  font-size: 24px;
  color: #4D0695;
}
#sign-up-container #input input, #sign-up-container #input textarea {
  padding: 16px 2px;
  width: 100%;
  outline: none;
  border: none;
}
#sign-up-container #input textarea {
  height: 185px;
  resize: none;
}
#sign-up-container span {
  align-self: self-start;
  line-height: 0;
  font-size: 12px;
}
#sign-up-container p {
  margin-top: 1rem;
  font-size: 16px;
  text-align: start;
}
#sign-up-container .btton {
  width: 70%;
  margin: auto;
  display: flex;
  padding: 16px 6px;
  justify-content: center;
  align-items: center;
  gap: 62px;
  border-radius: 30px;
  background: #4D0695;
  color: #FFF;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  outline: none;
  border: none;
}
#sign-up-container .btton i {
  font-size: 20px;
}
#sign-up-container #input:hover {
  border: 1px solid black;
}
#sign-up-container #input:focus-within,
#sign-up-container #input input:focus,
#sign-up-container #input textarea:focus {
  border-color: #4D0695;
}
#sign-up-container #x-btn {
  position: fixed;
  top: 1.5rem;
  right: 10rem;
  cursor: pointer;
  font-size: 24px;
}
#sign-up-container a {
  color: #4D0695;
  text-decoration: none;
}
#sign-up-container #password {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}
#sign-up-container #password #remember-me {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  align-items: center;
  text-align: center;
}

.ul-innovators {
  transition: 1s ease;
  opacity: 1;
}

.ul-innovators-active {
  transition: 1s ease;
  transform: translateX(-2rem);
  opacity: 0;
}

.ul-talents {
  position: absolute;
  top: 18rem;
  transition: 1s ease;
  opacity: 0;
  width: 300px;
  transform: translateX(2rem);
}

.ul-talents-active {
  position: absolute;
  top: 18rem;
  width: 300px;
  transition: transform 1s ease;
  opacity: 1;
  transform: translateX(0rem);
}

#display_toggle {
  display: none;
}

.form-container {
  display: flex;
  flex-direction: column !important;
}

@media (max-width: 1045px) {
  #header-container,
  #about,
  #offer,
  #works-container,
  #questions,
  footer,
  #sign-up-container {
    width: 1350px !important;
  }
  #sign-up-container {
    height: 100% !important;
  }
  .work-layer {
    display: none;
  }
  .question-header {
    width: 100% !important;
  }
  .contents-container {
    width: 100% !important;
  }
}
@media (max-width: 884px) {
  #sign-up-container {
    width: 100% !important;
    padding-bottom: 2rem !important;
  }
  #sign-up-container #redio-container {
    gap: 26px !important;
  }
  #sign-up-container #radio {
    width: 100%;
    font-size: 12px !important;
  }
  #sign-up-container #sign-in-layer {
    display: block;
    width: 100%;
    margin: 0rem;
  }
  #sign-up-container .lists-container {
    display: none;
  }
  #sign-up-container form {
    width: 100% !important;
    padding-inline: 1rem;
  }
  #sign-up-container #x-btn {
    right: 0.5rem;
  }
  #questions {
    width: 100% !important;
  }
  #works-container {
    height: -moz-fit-content !important;
    height: fit-content !important;
    padding-bottom: 0px !important;
  }
  .work-layer {
    display: none !important;
  }
  #story:hover .layer {
    transform: none !important;
  }
  #mission:hover .layer {
    transform: none !important;
  }
  #vision:hover .layer {
    transform: none !important;
    transition: none;
  }
  #header-container,
  #about,
  #offer,
  #works-container {
    width: 100% !important;
  }
  #header-container {
    width: 100%;
    height: 546px;
    flex-shrink: 0;
    display: block;
    padding: 0 2rem;
    justify-content: flex-end;
    margin-top: 0;
    border: 1px solid #000;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url("../public/images/mobil-bg.png"), lightgray 50%/cover no-repeat;
  }
  #header p, #header h1 {
    color: white;
  }
  #header-body {
    color: white;
  }
  #header-container button {
    box-shadow: 3px 3px 0px 0px #FFF;
  }
  .bg-img {
    display: none;
  }
  .offer-header {
    width: 729px !important;
  }
  .offer-header h2 {
    font-size: 38px !important;
  }
  .offer-header h6 {
    font-size: 14px !important;
  }
  .about-container {
    height: -moz-fit-content;
    height: fit-content;
    padding: 174px 3.5rem;
    margin-inline: 0 !important;
  }
  .grid-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    margin-top: 30px;
    align-items: center;
  }
  .grid-container #story,
  .grid-container #vision,
  .grid-container #mission {
    display: flex;
    padding: 30px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 406px;
    margin-bottom: 16px;
  }
  .grid-container #story,
  .grid-container #vision,
  .grid-container #mission:hover {
    transform: none;
    transition: none;
  }
  .grid-container .icon-con {
    position: relative;
    margin-bottom: 30px;
    transform: none;
  }
  .grid-container #story {
    background: #FBE4E7;
  }
  .grid-container #story .story-icon {
    background: url("../public/images/story-icon.png");
    padding: 80px;
    opacity: 1;
    background-repeat: no-repeat;
    background-size: cover;
    transform: none;
  }
  .grid-container #vision {
    background: #D4D8FE;
  }
  .grid-container #vision .vision-icon {
    background: url("../public/images/vision-icon.png");
    padding: 80px;
    opacity: 1;
    background-repeat: no-repeat;
    background-size: cover;
    transform: none;
  }
  .grid-container #mission {
    background: #C1F2FF;
  }
  .grid-container #mission .mission-icon {
    background: url("../public/images/mission-icon.png");
    padding: 80px;
    opacity: 1;
    background-repeat: no-repeat;
    background-size: cover;
    transform: none;
  }
  .works-header {
    width: 100% !important;
  }
  .works-header h4 {
    font-size: 20px !important;
  }
  .works-header h1 {
    font-size: 40px !important;
  }
  .content {
    width: 100% !important;
  }
  .content p {
    width: 100% !important;
  }
  #swiper-works {
    width: 100%;
    display: flex;
    margin-top: 40px;
  }
  #layer-content {
    background: #F8F5FB;
    gap: 30px;
    border-radius: 10px;
    padding: 16px;
  }
  #layer-content h2 {
    font-size: 16px;
  }
  footer {
    width: 100% !important;
    height: -moz-fit-content;
    height: fit-content;
    padding: 68px 3rem 1rem 3rem;
  }
  footer .footer-container {
    display: block;
  }
  footer .footer-ads {
    display: flex;
    flex-direction: column;
    gap: 11px;
    width: 100%;
    padding-inline: 10px;
  }
  footer img {
    border-radius: 50%;
    width: 100px !important;
    height: 100px !important;
  }
  footer span {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  footer .footer-contents {
    display: none;
  }
  footer hr {
    display: none;
  }
  footer .footer-contents-mobile {
    display: flex;
    flex-direction: column;
    padding: 4rem 2rem;
    gap: 30px;
  }
  footer #company-mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    font-family: "Manrope";
  }
  footer .footer-btn {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 4rem;
  }
  footer p {
    font-size: 14px;
  }
  footer #ul {
    padding-top: 0rem !important;
  }
  footer li {
    line-height: 30px;
  }
  footer a {
    font-size: 12px;
  }
  footer #hr {
    border: 1px solid white;
    margin-block: 15px;
    display: block !important;
  }
  footer #foot {
    display: block;
  }
  footer #h6 {
    align-self: center;
    font-size: 12px;
  }
  footer h5 {
    display: none;
  }
}
@media (max-width: 428px) {
  #header-container {
    padding: 0 0.5rem;
    height: 446px;
  }
  .header-layer {
    gap: 16px;
  }
  #header p {
    font-size: 24px;
  }
  #header h1 {
    font-size: 70px;
  }
  #header-body {
    font-size: 12px;
    width: 300px;
    line-height: 20px !important;
  }
  #header-container button {
    margin-top: 40px !important;
  }
  .about-container {
    padding: 40px 1.5rem;
  }
  .about-container h3 {
    font-size: 16px;
    line-height: 35px;
  }
  .about-container h1,
  .about-container h1 span {
    font-size: 20px;
    line-height: 26px;
    margin: 16px 0 !important;
  }
  .about-container span {
    font-size: 12px;
    line-height: 20px;
  }
  #offer {
    padding-inline: 2rem !important;
  }
  .offer-header {
    width: 100% !important;
    padding-inline: 0 !important;
  }
  .offer-header h2 {
    font-size: 25px !important;
    line-height: 30px !important;
    letter-spacing: 0px;
    padding-inline: 0 !important;
  }
  .offer-header h6 {
    font-size: 14px !important;
    line-height: 35px !important;
  }
  .slider-container {
    padding: 16px !important;
    width: 354px !important;
    height: 276px !important;
  }
  .slider-container .slide-layer {
    gap: 20px;
    align-self: stretch;
  }
  .slider-container .slide-layer img {
    width: 80px;
    height: 80px;
  }
  .slider-container .slide-layer h1 {
    font-size: 16px;
  }
  .slider-container .slide-layer span {
    width: 322px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
  .grid-container {
    margin-inline: 1.5rem;
  }
  .grid-container .mission-icon,
  .grid-container .vision-icon,
  .grid-container .story-icon {
    padding: 40px !important;
  }
  .works-header {
    width: 100% !important;
  }
  .works-header h4 {
    font-size: 16px !important;
    line-height: 35px;
  }
  .works-header h1 {
    font-size: 25px !important;
    line-height: 30px !important;
    padding-inline: 0 !important;
  }
  .content {
    width: 100% !important;
  }
  .content p {
    width: 100% !important;
  }
  .content i {
    background-color: #F8F5FB !important;
  }
  #swiper-works {
    width: 100%;
    display: flex;
    margin-top: 10px !important;
  }
  #layer-content {
    background: #F8F5FB;
    gap: 30px;
    border-radius: 10px;
    padding: 16px;
  }
  #layer-content h2 {
    font-size: 16px !important;
  }
  .header {
    width: 100% !important;
  }
  .header h2 {
    font-size: 16px !important;
    line-height: 30px !important;
  }
  .header h3 {
    font-size: 16px !important;
    line-height: 30px !important;
  }
  .content p {
    font-size: 12px !important;
    line-height: 20px !important;
  }
  #works-container {
    padding-inline: 2rem;
    margin-top: 80px !important;
  }
  #testimonials #h6 {
    font-size: 12px;
    line-height: 18px !important;
  }
  #testimonials #h2 {
    font-size: 20px;
  }
  #info {
    display: flex;
    width: 316px;
    justify-content: space-between;
    position: relative;
  }
  #info #name img {
    width: 64px;
    height: 64px;
  }
  #info #name div h6 {
    line-height: 10px;
    padding-top: 1rem;
  }
  #info #name div p {
    font-size: 12px;
  }
  .testimonials button {
    padding: 18px 65px;
    border: 2px solid #FFF;
    background: #4D0695;
    box-shadow: 3px 3px 0px 0px #FFF;
    color: #FFF;
  }
  .question-header {
    padding-inline: 0.5rem !important;
  }
  .question-header h1 {
    font-size: 25px !important;
    line-height: 18px !important;
  }
  .question-header h4 {
    font-size: 12px !important;
    line-height: 20px !important;
  }
  .txt-contents {
    width: 170px !important;
  }
  .txt-contents span {
    font-size: 10px !important;
    padding-bottom: 0rem !important;
  }
  .txt-contents h6 {
    font-size: 10px !important;
  }
  .layer i {
    font-size: 12px !important;
  }
  footer {
    width: 100%;
    padding: 68px 0px;
  }
  #radio {
    gap: 0.5rem !important;
    padding-inline: 0 !important;
  }
  #radio h5 {
    font-size: 11px !important;
  }
  #radio input, #radio #sign-up-container #input textarea, #sign-up-container #input #radio textarea {
    margin-right: 0px !important;
    margin-left: 0 !important;
  }
  .question-header h1 {
    line-height: 2rem !important;
  }
  .icon-con {
    display: none;
  }
  #story,
  #vision,
  #mission {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .layer h4 {
    font-size: 20px !important;
  }
  .layer p {
    font-size: 12px !important;
  }
}
@media (max-width: 375px) {
  .swiper-pagination-bullet {
    width: 0.5rem !important;
  }
  .slider-container {
    width: 300px !important;
  }
  span {
    width: 250px !important;
  }
  #info {
    width: 270px !important;
  }
  #info #icon i {
    font-size: 18px;
  }
  #info #name {
    width: 140px !important;
  }
  #info #name div h6 {
    padding-top: 0.3rem !important;
    line-height: 10px !important;
  }
  #info #name img {
    width: 44px !important;
    height: 44px !important;
  }
  #sign-up-container span {
    text-align: start;
  }
}
@media (max-width: 320px) {
  #header-body {
    font-size: 12px;
    width: 260px;
    line-height: 20px !important;
  }
  .slider-container {
    width: 270px !important;
  }
  .works-header {
    width: 100% !important;
  }
  .works-header h4 {
    font-size: 11px !important;
  }
  #offer {
    padding-left: 1.5rem !important;
    padding-right: 0 !important;
  }
  .offer-header {
    padding-right: 1.5rem !important;
  }
  .offer-header h2 {
    font-size: 18px !important;
    line-height: 20px !important;
    letter-spacing: 0px;
    padding: 0;
  }
  .offer-header h6 {
    font-size: 10px !important;
    line-height: 30px !important;
  }
  #info {
    width: 230px !important;
  }
  .testimonials button {
    padding: 12px 22px;
    font-size: 10px;
    margin-right: 1.5rem;
  }
  #sign-up-container #radio {
    gap: 0.5rem !important;
    padding-inline: 0 !important;
  }
  #sign-up-container #radio h5 {
    font-size: 9px !important;
  }
  #sign-up-container #radio input, #sign-up-container #radio #input textarea, #sign-up-container #input #radio textarea {
    margin-right: 0px !important;
    margin-left: 0 !important;
  }
  #sign-up-container span {
    text-align: start;
  }
}/*# sourceMappingURL=App.css.map */