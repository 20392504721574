.form-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  align-items: center;
}

.form-layer{
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-left: 4rem;
}

.form-layer h1{
  color: #2E2F35;
  font-family: "Merriweather";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.form-layer span{
  color: #58595D;
  font-family: "Manrope";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
}

.form-layer a{
  width: 30%;
  border-radius: 12px;
  border: 2px solid #2E2F35;
  background: #4D0695;
  box-shadow: 3px 3px 0px 0px #2E2F35;
  padding: 14px 25.859px 12px 25px;
  color: #FFF;
  text-align: center;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;
  transition: 2s ease;
}

.form-layer a:hover{
  border: 2px solid white;
  background: white;
  box-shadow: 3px 3px 0px 0px purple;
  color: purple;
}

.form-image{
  background-image: url("../../../public/images/customer-care.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 751px;
}

@media(max-width:1045px){
  .form-container{
    width: 1350px;
  }
}

@media(max-width:884px){
  .form-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;

  }

  .form-image{
    order: -1;
    height: 851px;
    width: 100%;
  }

  .form-layer{
    padding-block: 3rem;
  }

  .form-layer a{
    width: 50%;
  }
}

@media(max-width:429px){

  .form-layer{
    padding-left: 2rem;
  }
  .form-layer h1{
    font-size: 25px;
    line-height: 15px;
  }

  .form-layer span{
    font-size: 16px;
  }

  .form-layer a{
    width: 70%;
  }
}